.container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.36s ease-in-out;
  background-color: #f1f5f9; /* soft white slightly blue */
}

.container-hide {
  opacity: 0;
}

.link {
  transition: all 0.18s ease-in-out;
  stroke: #b9bec6;
}

.nodeBox {
  fill: white;
  stroke: #b9bec6;
  stroke-width: 1px;
  transition: all 0.18s ease-in-out;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

.node:hover .nodeBox {
  stroke-width: 2px;
}

.nodeContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 2px;
}

.nodeName {
  font-size: 12px;
  font-weight: 600;
  color: rgb(45, 66, 106);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 0.82;
  width: 100%;
}

.nodeTitle {
  font-size: 10px;
  color: rgb(45, 66, 106);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 0.96;
  width: 100%;
  margin-top: 2px;
}

.node {
  cursor: pointer;
  transition: all 0.18s ease-in-out;
  touch-action: manipulation;
}

.toggleButton {
  cursor: pointer;
  touch-action: manipulation;
  position: relative;
}

.toggleCircle {
  fill: rgb(255, 255, 255);
  stroke: rgb(45, 66, 106);
  stroke-width: 1px;
}

.toggleButton:hover .toggleCircle {
  fill: rgba(243, 243, 244);
}

.toggleText {
  font-size: 12px;
  fill: #666;
  pointer-events: none;
}

.toggleIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: rgb(45, 66, 106);
  pointer-events: none;
}

.orgContainer svg:active {
  cursor: grabbing;
}

/* Only apply these when pan/zoom is enabled */
.links {
  pointer-events: none;
}

.nodes {
  pointer-events: all;
}
