.container {
  height: 100%;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.title {
  font-size: 14px;
  color: #2d426a;
  font-weight: 600;
  white-space: nowrap;
}

.info {
  margin-left: 4px;
  cursor: pointer;
  line-height: 16px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.titleContainer:hover .info {
  opacity: 1;
}

.chartContainer {
  display: flex;
  width: 100%;
  height: calc(100% - 21px);
}

.labelContainer {
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  z-index: 1;
}

.plotContainer {
  width: 50%;
  height: 100%;
}

.value {
  font-size: 28px;
  font-weight: 600;
  color: #2d426a;
  white-space: nowrap;
}

.delta {
  font-size: 12px;
  font-weight: 600;
  color: #2d426a;
}

.delta.positive {
  color: #40b975;
}

.delta.negative {
  color: #ff5876;
}

.plotContainer {
  width: 50%;
}
