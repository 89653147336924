body {
  overflow: hidden;
}
// main posting chart tooltips
.tooltip-added-main-postings-chart {
  position: absolute;
  background: #2d426a;
  padding: 12px;
  color: #ffffff;
  border-radius: 4px;
  font-size: 11px;
  font-family: 'Roboto';
  left: 0px;
  top: 0px;
  overflow-wrap: break-word;
  z-index: 9999;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.tooltip-added-main-postings-chart:before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -7px;
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  border-top: 10px solid #2d426a;
  border-left: 8px solid transparent;
  margin-left: -8px;
}

.tooltip-removed-main-postings-chart {
  position: absolute;
  background: #2d426a;
  padding: 12px;
  color: #ffffff;
  border-radius: 4px;
  font-size: 11px;
  font-family: 'Roboto';
  left: 0px;
  top: 0px;
  overflow-wrap: break-word;
  z-index: 9999;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.tooltip-removed-main-postings-chart:before {
  content: '';
  position: absolute;
  left: 50%;
  top: -7px;
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  border-bottom: 10px solid #2d426a;
  border-left: 8px solid transparent;
  margin-left: -8px;
}

// sankey tooltips
.tooltip-sankey-chart,
.tooltip-sankey-chart-top,
.tooltip-sankey-chart-node {
  position: absolute;
  background: #2d426a;
  padding: 12px;
  color: #ffffff;
  border-radius: 4px;
  font-size: 10px;
  font-family: 'Roboto';
  left: 0px;
  top: 0px;
  overflow-wrap: break-word;
  z-index: 9999;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.tooltip-sankey-chart:before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -7px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-top: 10px solid #2d426a;
  border-right: 8px solid transparent;
  margin-right: -8px;
}

.tooltip-sankey-chart-top:before {
  content: '';
  position: absolute;
  left: 50%;
  top: -7px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-bottom: 10px solid #2d426a;
  border-right: 8px solid transparent;
  margin-left: -8px;
}

.axis-grid line {
  stroke: #c0c6d2; //#e6e8ed;
  stroke-width: 1.3;
  stroke-dasharray: 1, 3;
  stroke-linecap: round;
}

.axis-grid-game line {
  stroke: rgba(255, 255, 255, 0.35);
  stroke-width: 1.3;
  stroke-dasharray: 1, 3;
  stroke-linecap: round;
}

.axis-grid-gameV2 line {
  visibility: hidden;
}

.axis-label-main-postings {
  font-size: 10px;
  color: #636d7e; //#818ea6;
  font-family: 'Source Sans Pro', 'Roboto', sans-serif;
}

.node-text-wrap-2 {
  font-family: 'Source Sans Pro', 'Roboto', sans-serif;
  color: #2d426a;
  // TO WRAP TEXT:
  // display: inline;
  // overflow-wrap: break-word;
  // line-height: 1;
  // TO WRAP TO 3 LINES MAX:
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  line-height: 1.1;
  //width: 100%;
}

.node-text-wrap-3 {
  font-family: 'Source Sans Pro', 'Roboto', sans-serif;
  color: #2d426a;
  // TO WRAP TEXT:
  // display: inline;
  // overflow-wrap: break-word;
  // line-height: 1;
  // TO WRAP TO 3 LINES MAX:
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  line-height: 1.1;
  //width: 100%;
}

.node-text-ellipsis {
  font-family: 'Source Sans Pro', 'Roboto', sans-serif;
  color: #2d426a;
  // to cut off with ellipses:
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.axis-label-histogram {
  font-size: 10px;
  color: #636d7e; //#818ea6;
  font-family: 'Source Sans Pro', 'Roboto', sans-serif;
}

.react-node text {
  font-family: 'Source Sans Pro', 'Roboto';
  color: #636d7e; //#818ea6;
}
.tooltip {
  position: absolute;
  // background: #2d426a;
  background: white;
  padding: 12px;
  color: white;
  border-radius: 4px;
  font-size: 10px;
  font-family: 'Source Sans Pro', 'Roboto';
  left: 0px;
  top: 0px;
  overflow-wrap: break-word;
  z-index: 9999;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.tooltip-bar-chart-horizontal,
.tooltip-bar-chart-horizontal-right {
  position: absolute;
  background: #2d426a;
  // background: white;
  padding: 12px;
  color: #ffffff;
  border-radius: 4px;
  font-size: 11px;
  font-family: 'Source Sans Pro', 'Roboto';
  left: 0px;
  top: 0px;
  overflow-wrap: break-word;
  z-index: 9999;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.tooltip-bar-chart-horizontal:before {
  content: '';
  position: absolute;
  bottom: 50%;
  left: -7px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-right: 10px solid #2d426a;
  border-bottom: 8px solid transparent;
  margin-bottom: -8px;
}

.tooltip-bar-chart-horizontal-right:before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -7px;
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  border-top: 10px solid #2d426a;
  border-left: 8px solid transparent;
  margin-left: -8px;
}

.tt-title-bar-chart-horizontal {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tooltip-stacked-bar-chart,
.tooltip-stacked-bar-chart-right {
  position: absolute;
  background: #2d426a;
  // background: white;
  padding: 12px;
  color: #ffffff;
  border-radius: 4px;
  font-size: 10px;
  font-family: 'Source Sans Pro', 'Roboto';
  left: 0px;
  top: 0px;
  overflow-wrap: break-word;
  z-index: 9999;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.tooltip-stacked-bar-chart {
  position: absolute;
  background: #2d426a;
  // background: white;
  padding: 12px;
  color: #ffffff;
  border-radius: 4px;
  font-size: 10px;
  font-family: 'Source Sans Pro', 'Roboto';
  left: 0px;
  top: 0px;
  overflow-wrap: break-word;
  z-index: 9999;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.tooltip-stacked-bar-chart:before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -7px;
  width: 0;
  height: 0;
  border-right: 6px solid transparent;
  border-top: 8px solid #2d426a;
  border-left: 6px solid transparent;
  margin-left: -6px;
}

.tooltip-stacked-bar-chart-right:before {
  content: '';
  position: absolute;
  bottom: 50%;
  right: -7px;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-left: 8px solid #2d426a;
  border-bottom: 6px solid transparent;
  margin-bottom: -6px;
}

.tooltip-line-chart {
  position: absolute;
  background: #2d426a;
  // background: white;
  padding: 12px;
  color: #ffffff;
  border-radius: 4px;
  font-size: 11px;
  font-family: 'Source Sans Pro', 'Roboto';
  left: 0px;
  top: 0px;
  overflow-wrap: break-word;
  z-index: 9999;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.tooltip-line-chart-arrow-left:before {
  content: '';
  position: absolute;
  bottom: 15%;
  left: -7px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-right: 10px solid #2d426a;
  border-bottom: 8px solid transparent;
  margin-bottom: -8px;
}

.tooltip-line-chart-arrow-right:before {
  content: '';
  position: absolute;
  bottom: 15%;
  right: -7px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-left: 10px solid #2d426a;
  border-bottom: 8px solid transparent;
  margin-bottom: -8px;
}

.tooltip-kde {
  position: absolute;
  background: #2d426a;
  padding: 12px;
  color: #ffffff;
  border-radius: 4px;
  font-size: 11px;
  font-family: 'Roboto';
  left: 0px;
  top: 0px;
  overflow-wrap: break-word;
  z-index: 9999;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.tooltip-kde-arrow-left:before {
  content: '';
  position: absolute;
  bottom: 15%;
  left: -7px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-right: 10px solid #2d426a;
  border-bottom: 8px solid transparent;
  margin-bottom: -8px;
}

.tooltip-kde-arrow-right:after {
  content: '';
  position: absolute;
  bottom: 15%;
  right: -7px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-left: 10px solid #2d426a;
  border-bottom: 8px solid transparent;
  margin-bottom: -8px;
}

.tooltip-line-chart-arrow-left-large:before {
  content: '';
  position: absolute;
  bottom: 15%;
  left: -7px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-right: 10px solid #2d426a;
  border-bottom: 8px solid transparent;
  margin-bottom: -8px;
}

.tooltip-line-chart-arrow-right-large:before {
  content: '';
  position: absolute;
  bottom: 15%;
  right: -7px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-left: 10px solid #2d426a;
  border-bottom: 8px solid transparent;
  margin-bottom: -8px;
}

.axis-grid line {
  stroke: #e6e8ed;
  stroke-dasharray: 1, 3;
}

.tooltip:before {
  content: '';
  position: absolute;
  bottom: -6px;
  left: 0px;
  width: 0;
  height: 0;
  border-top: 8px solid white;
  border-right: 10px solid transparent;
}

.legend-text {
  font-family: 'Source Sans Pro', 'Roboto';
  font-size: 10px;
  color: #636d7e; //#818ea6;
}
.stacked-label-text {
  font-size: 10px;
  font-family: 'Source Sans Pro', 'Roboto';
}

.title {
  font-size: 12px;
  font-weight: 600;
  padding-bottom: 10px;
  padding-left: 7px;
}

.linechart-title {
  font-size: 12px;
  font-weight: 600;
  padding-bottom: 10px;
  padding-left: 0px;
  color: #ffffff;
}

.tt-container {
  font-family: 'Source Sans Pro', 'Roboto';
  color: #ffffff;
}

.row {
  display: flex;
  flex-direction: row;
  padding-bottom: 6px;
  width: 195px;
}

.tt-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 10px;
  font-weight: 400;
  opacity: 0.81;
  margin: 0px 8px;
  flex: 1 1 auto;
}

.tt-count {
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  font-size: 10px;
  font-weight: 500;
  opacity: 0.81;
}

.tt-percent-container {
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  font-size: 10px;
  font-weight: 500;
  opacity: 0.81;
  width: 31px;
}

.tt-cdf {
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  font-size: 10px;
  font-weight: 500;
  opacity: 0.81;
  padding-left: 14px;
  padding-bottom: 6px;
}

.tt-slash {
  flex: 0 0 auto;
}

.tt-percent {
  flex: 1 1 auto;
  text-align: right;
}

.tt-color-container {
  display: flex;
  position: relative;
  flex-direction: row;
  flex: 0 0 auto;
  font-size: 10px;
  font-weight: 500;
  opacity: 0.81;
  width: 7px;
  // line-height: 20px;
}

.tooltip-bar-chart-vertical,
.tooltip-bar-chart-vertical-right {
  position: absolute;
  background: #2d426a;
  // background: white;
  padding: 12px;
  color: #ffffff;
  border-radius: 4px;
  font-size: 11px;
  font-family: 'Source Sans Pro', 'Roboto';
  left: 0px;
  top: 0px;
  overflow-wrap: break-word;
  z-index: 9999;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.tooltip-bar-chart-vertical:before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -7px;
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  border-top: 10px solid #2d426a;
  border-left: 8px solid transparent;
  margin-left: -8px;
}

.tooltip-bar-chart-vertical-right:before {
  content: '';
  position: absolute;
  bottom: 50%;
  right: -7px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-left: 10px solid #2d426a;
  border-bottom: 8px solid transparent;
  margin-bottom: -8px;
}

.tooltip-word-cloud {
  position: absolute;
  background: #2d426a;
  padding: 12px;
  color: #ffffff;
  border-radius: 4px;
  font-size: 11px;
  font-family: 'Source Sans Pro', 'Roboto';
  left: 0px;
  top: 0px;
  overflow-wrap: break-word;
  z-index: 9999;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.wordcloud-negative-word-cloud-text {
  font-family: 'Source Sans Pro', 'Roboto';
}

.wordcloud-positive-word-cloud-text {
  font-family: 'Source Sans Pro', 'Roboto';
}

.wordcloud-negative-word-cloud-text-d3 {
  font-family: 'Source Sans Pro', 'Roboto';
  text-anchor: middle;
}

.wordcloud-positive-word-cloud-text-d3 {
  font-family: 'Source Sans Pro', 'Roboto';
  text-anchor: middle;
}

.mirror-bar-chart-horizontal-arrow {
  stroke: #2d426a;
  stroke-width: 2;
  opacity: 0.7;
}

.mirror-bar-chart-horizontal-text {
  fill: #2d426a;
  font-weight: 600;
  font-family: 'Source Sans Pro', 'Roboto', sans-serif;
  opacity: 0.7;
}

.mirror-bar-chart-horizontal-axis-label {
  color: #2d426a;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Source Sans Pro', 'Roboto', sans-serif;
  opacity: 0.7;
}

.grouped-bar-chart-horizontal-axis-label {
  color: #636d7e;
  font-weight: 400;
  font-family: 'Source Sans Pro', 'Roboto', sans-serif;
}

.tooltip-mirror-bar-chart-horizontal {
  position: absolute;
  background: #2d426a;
  padding: 12px;
  color: #ffffff;
  border-radius: 4px;
  font-size: 11px;
  font-family: 'Source Sans Pro', 'Roboto', sans-serif;
  left: 0px;
  top: 0px;
  overflow-wrap: break-word;
  z-index: 9999;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.tooltip-mirror-bar-chart-horizontal:before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -7px;
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  border-top: 10px solid #2d426a;
  border-left: 8px solid transparent;
  margin-left: -8px;
}

.tooltip-boxplot {
  @extend .tooltip-mirror-bar-chart-horizontal;
}

.tooltip-boxplot-right {
  position: absolute;
  background: #2d426a;
  padding: 12px;
  color: #ffffff;
  border-radius: 4px;
  font-size: 11px;
  font-family: 'Source Sans Pro', 'Roboto', sans-serif;
  left: 0px;
  top: 0px;
  overflow-wrap: break-word;
  z-index: 9999;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.tooltip-boxplot-right:before {
  content: '';
  position: absolute;
  bottom: 50%;
  right: -7px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-left: 10px solid #2d426a;
  border-bottom: 8px solid transparent;
  margin-bottom: -8px;
}

.tooltip-boxplot-right:before {
  content: '';
  position: absolute;
  bottom: 50%;
  right: -7px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-left: 10px solid #2d426a;
  border-bottom: 8px solid transparent;
  margin-bottom: -8px;
}

.tree-node-text {
  font-family: 'Source Sans Pro', 'Roboto';
  font-size: 16px;
  font-weight: 600;
  fill: #2d426a;
}

.tooltip-tree {
  position: absolute;
  background: #2d426a;
  // background: white;
  padding: 12px;
  color: #ffffff;
  border-radius: 4px;
  font-size: 11px;
  font-family: 'Source Sans Pro', 'Roboto';
  left: 0px;
  top: 0px;
  overflow-wrap: break-word;
  z-index: 9999;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.tooltip-tree:before {
  content: '';
  position: absolute;
  left: 50%;
  top: -7px;
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  border-bottom: 10px solid #2d426a;
  border-left: 8px solid transparent;
  margin-left: -8px;
}

.tree-slider,
.scatter-slider {
  position: absolute;

  > input[type='range'] {
    background-color: transparent;
    -webkit-appearance: none;
  }

  > input[type='range']::-webkit-slider-runnable-track {
    width: 300px;
    height: 6px;
    background: #bec6d4;
    border: none;
    border-radius: 3px;
  }

  > input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #3dc679;
    margin-top: -5px;
  }

  > input[type='range']:focus::-webkit-slider-thumb {
    background: #3dc679;
    border: 2px solid white;
  }

  > input[type='range']:focus {
    outline: none;
  }

  > input[type='range']:focus::-webkit-slider-runnable-track {
    background: #bec6d4;
  }
}

.tooltip-scatter {
  position: absolute;
  background: #2d426a;
  // background: white;
  padding: 12px;
  color: #ffffff;
  border-radius: 4px;
  font-size: 11px;
  font-family: 'Source Sans Pro', 'Roboto';
  left: 0px;
  top: 0px;
  overflow-wrap: break-word;
  z-index: 9999;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.found-dot {
  fill: red;
  background-color: red;
  color: red;
}

// d3 Stacked Bar Chart Horizontal Legend
.box-container {
  height: 30px;
  width: auto;
  background: white;
  margin: auto;
  top: 10px;
  position: absolute;
  display: block;
  overflow-y: auto;
  margin-right: 16px;
  scrollbar-width: auto;
  scrollbar-color: #ffffff #ffffff;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 10px;
    border: 4px solid #ffffff;
  }
}

.box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  gap: 0px 12px;
}

.item {
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.colorSq {
  width: 7px;
  height: 7px;
  display: inline-block;
  border-radius: 2px;
}

.name {
  font-family: 'Source Sans Pro', 'Roboto', sans-serif;
  font-size: 10px;
  color: #636d7e; // original: #818ea6
  margin-left: 4px;
  display: inline-block;
}

.plot1:hover .box-container,
.plot2:hover .box-container,
.plot3:hover .box-container,
.plot4:hover .box-container,
.plot5:hover .box-container,
.plot6:hover .box-container,
.plot7:hover .box-container,
.plot8:hover .box-container,
.plot9:hover .box-container,
.plot10:hover .box-container,
.plot11:hover .box-container,
.plot12:hover .box-container {
  scrollbar-color: #e3e3e3 #ffffff;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e3e3e3;
    border-radius: 10px;
    border: 4px solid #ffffff;
  }
}

.stacked-bar-y-labels {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  line-height: 1.1;
  padding-right: 2px;
  width: 100%;
}

.boxplot-horizontal-axis-label {
  color: #2d426a;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Source Sans Pro', 'Roboto';
}
