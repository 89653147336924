.popoutTree {
  width: 100%;
  background-color: white;
  border-radius: var(--chakra-radii-md);
  scrollbar-width: auto;
  scrollbar-color: #e3e3e3 #ffffff;
}

.popoutTree::-webkit-scrollbar {
  width: 14px;
}

.popoutTree::-webkit-scrollbar-track {
  background: transparent;
}

.popoutTree::-webkit-scrollbar-thumb {
  background-color: #e6ebf4;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.rootTreeContainer {
  position: relative;
}

.floatingTree {
  position: absolute;
  padding: 12px;
  width: 280px;
  background-color: white;
  border-radius: var(--chakra-radii-md);
  box-shadow: var(--chakra-shadows-xl);
  border: 1px solid #e6ebf4;
  z-index: 1;
}

.branchContainer {
  display: flex;
  align-items: center;
  color: #96a0b5;
}

.branchLabel {
  margin-bottom: 1px;
  font-size: var(--chakra-fontSizes-xs);
  color: #96a0b5;
  font-weight: 600;
  letter-spacing: 0.8px;
}

.branchContainer span {
  display: none;
  margin-left: 4px;
  height: 12px;
  align-items: center;
}

.branchContainer:hover span {
  display: flex;
  cursor: pointer;
}

.selectionControlContainer {
  background-color: white;
}

.selectionControl {
  display: flex;
  justify-content: space-between;
  padding: 0 12px 12px;
}
