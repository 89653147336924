.inovua-react-scroll-container__wrapper > div::-webkit-scrollbar {
  width: 14px;
}

.inovua-react-scroll-container__wrapper > div::-webkit-scrollbar-track {
  background: transparent;
}

.inovua-react-scroll-container__wrapper:hover > div::-webkit-scrollbar-thumb {
  background-color: #e6ebf4;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.inovua-react-scroll-container__wrapper > div::-webkit-scrollbar-thumb {
  background-color: transparent;
  border: transparent;
}

.InovuaReactDataGrid__header.InovuaReactDataGrid__header--direction-ltr {
  background-color: transparent;
}
