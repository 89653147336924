.itemText {
  font-size: 12px;
  color: var(--chakra-colors-text-primary);
  width: 200px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.checkbox {
  width: 100%;
  padding-left: var(--chakra-space-1);
}

.highlight {
  display: flex;
  height: 24px;
}

.highlight:hover {
  cursor: pointer;
  background-color: var(--chakra-colors-gray-50);
  border-radius: 4px;
}
