@import-normalize;
@import './fonts/_fonts.scss';
@import './theme.css';

$rlsilver: #ebeef3;
$rlnavy: #2d426a;
$rlreveal300: #89f2b7;

html,
body {
  margin: 0;
  padding: 0;
  background-color: $rlsilver;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  .rl-overlay {
    width: 100%;
    height: 100%;
    background: $rlnavy;
    position: fixed;
    z-index: 8999;
    opacity: 0.9;
  }
}

.textRender {
  font-family: 'Roboto';
  font-size: 0px;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  color: white;
}

.main {
  display: block;
  grid-area: main;
  background-color: $rlsilver;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInDelay {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.5s;
  -webkit-animation: fadeIn 0.5s;
}
