.permanent-inline-date-select-popper {
  transform: none !important;
  position: initial !important;
}

.react-datepicker {
  width: 100%;
}

.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__month-wrapper {
  display: flex;
  justify-content: space-around;
}

.react-datepicker__month-text {
  flex-grow: 1;
}
