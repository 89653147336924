.noDataContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.noDataImage {
  width: 50%;
  margin-bottom: 12px;
}

.noDataText {
  font-size: 16px;
  font-weight: 500;
  color: #667085;
  text-align: center;
}
