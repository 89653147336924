.plotLoaderContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffffbb;
  z-index: 10;
}

.plotLoaderImage {
  width: 85px;
}
