.card {
  padding: 12px 16px;
  height: 100%;
  transition:
    width 0.3s ease-in-out,
    height 0.3s ease-in-out;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.title {
  font-size: 14px;
  color: #2d426a;
  font-weight: 600;
  white-space: nowrap;
}

.info {
  margin-left: 4px;
  cursor: pointer;
  line-height: 16px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.titleContainer:hover .info {
  opacity: 1;
}

.topRight {
  display: flex;
}

.menuButton {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.card:hover .menuButton {
  opacity: 1;
}

.menuItem {
  font-size: 14px;
}

.menuItem > :global(.chakra-menu__icon-wrapper) {
  margin-right: 0.5rem;
}
