@keyframes bobble {
  0% {
    transform: translateY(8%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes bobble-out {
  100% {
    transform: translateY(8%);
  }
  0% {
    transform: translateY(0%);
  }
}
.landing-break-line {
  display: none;
}
