.nodeContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 4px;
  height: 100%;
  width: 100%;
  max-width: 100%;
}

.nodeContainer:hover {
  cursor: pointer;
  background-color: var(--chakra-colors-gray-50);
  border-radius: 4px;
}

.checkbox span[data-disabled]:first-of-type {
  display: none;
}
.checkbox span[data-disabled] {
  opacity: 1;
  margin-left: 0;
}

.labelContainer {
  display: flex;
  align-items: center;
}

.label {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: var(--chakra-colors-text-primary);
}

.suggestedResult {
  margin-left: 4px;
  color: var(--chakra-colors-gray-400);
}

.labelContainer > span {
  color: #96a0b5;
  display: none;
  margin-left: 4px;
  height: 12px;
  align-items: center;
}

.labelContainer:hover > span {
  display: flex;
  cursor: pointer;
}

.tooltipBold {
  font-weight: 900;
}

.drilledInto {
  background-color: var(--chakra-colors-gray-50);
  border-radius: 4px;
}
