.treeSelectionContainer {
  position: relative;
  background-color: white;
}

.noSearchText {
  position: absolute;
  color: var(--chakra-colors-text-primary);
  font-size: var(--chakra-fontSizes-xs);
  font-weight: 600;
  letter-spacing: 0.8px;
}

.row:focus {
  outline: none;
}

.tree {
  height: unset;
}

.tree:hover {
  cursor: pointer;
}

.tree::-webkit-scrollbar-track {
  background: transparent;
}

.tree::-webkit-scrollbar-thumb {
  background-color: #e6ebf4;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

[role='treeitem']:has(> .invisible) {
  display: none;
}
