.itemContainer {
  display: flex;
  margin-top: 2px;
}

.hidden {
  display: none;
}

.selected {
  background-color: var(--chakra-colors-gray-50);
  border-radius: 4px;
}

.highlight:hover {
  cursor: pointer;
  background-color: var(--chakra-colors-gray-50);
  border-radius: 4px;
}

.item {
  width: 100%;
}

.disabled {
  pointer-events: none;
}

.nodeText {
  width: 100%;
  font-size: 12px;
  color: var(--chakra-colors-text-primary);
}

.nodeLabel {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.defaultTag {
  display: inline-block;
}
.tooltipLabel {
  /* can insert rules here to style tooltip label */
}

.tooltipViewLabel {
  /* can insert rules here to style tooltip view label */
}

.disabledText {
  opacity: 0.5;
}

.disabledCheckbox {
  opacity: 0.4;
}
