.disablePointerEvents {
  pointer-events: none;
}

.dropdownInput {
  cursor: pointer;
}

.fixedSizedTree {
  overflow: scroll;
  scrollbar-width: auto;
  scrollbar-color: #e3e3e3 #ffffff;
}

.fixedSizedTree::-webkit-scrollbar {
  width: 14px;
}

.fixedSizedTree::-webkit-scrollbar-track {
  background: transparent;
}

.fixedSizedTree::-webkit-scrollbar-thumb {
  background-color: #e6ebf4;
  border-radius: 10px;
  border: 3px solid #ffffff;
}
