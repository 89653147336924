.columnFieldSet {
  border-width: 2px;
  padding: 15px;
  border-radius: 20px;
  margin-bottom: 20px;
}

.columnFieldSetHeading {
  margin-left: 10px;
  padding-left: 3px;
  padding-right: 3px;
}
