.contentContainer {
  padding-bottom: 75px;
  -webkit-mask-image: linear-gradient(black calc(100% - 70px), transparent),
    linear-gradient(black, black);
  mask-image: linear-gradient(black calc(100% - 70px), transparent),
    linear-gradient(black, black);
  -webkit-mask-size: calc(100% - 8px) 100%, 8px 100%;
  mask-size: calc(100% - 8px) 100%, 8px 100%;
  -webkit-mask-position: 0 0, 100% 0;
  mask-position: 0 0, 100% 0;
  -webkit-mask-repeat: no-repeat, no-repeat;
  mask-repeat: no-repeat, no-repeat;
}

.dataBuilderContainer {
  -webkit-mask-image: linear-gradient(black calc(100% - 70px), transparent),
    linear-gradient(black, black);
  mask-image: linear-gradient(black calc(100% - 70px), transparent),
    linear-gradient(black, black);
  -webkit-mask-size: calc(100% - 8px) 100%, 8px 100%;
  mask-size: calc(100% - 8px) 100%, 8px 100%;
  -webkit-mask-position: 0 0, 100% 0;
  mask-position: 0 0, 100% 0;
  -webkit-mask-repeat: no-repeat, no-repeat;
  mask-repeat: no-repeat, no-repeat;
  display: flex;
}
