$DATAGRID_THEME_NAME: 'rl-light';
$INOVUA_DATAGRID_ACCENT_COLOR: #afbccf;
$INOVUA_DATAGRID_ROW_ACTIVE_BORDER_WIDTH: '0px';

.InovuaReactDataGrid {
  min-height: 275px;

  // [data-name='scroller'] {
  //   overflow-x: hidden !important;
  // }

  .inovua-react-scroll-container__scrollbar--orientation-horizontal,
  .inovua-react-scroll-container__scrollbar--direction-ltr {
    display: none;
  }
}

.InovuaReactDataGrid__row {
  cursor: pointer;
}

.InovuaReactDataGrid__column-layout {
  min-width: 100%;
}

@import '@inovua/reactdatagrid-community/style/theme/default-light/index.scss';
