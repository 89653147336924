.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.container svg {
  overflow: visible;
}

.chart {
  cursor: pointer;
}

.area {
  stroke-width: 2px;
  fill-opacity: 0.2;
}

.xAxisLabel {
  font-size: 12px;
  text-anchor: middle;
  font-family: 'Source Sans Pro', sans-serif;
  fill: #636d7e;
}

.xAxisLabel.firstTick {
  text-anchor: start;
}
