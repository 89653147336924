@charset "UTF-8";
:root {
  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #495057;
  --text-color-secondary: #6c757d;
  --primary-color: #3dc679;
  --primary-color-text: #ffffff;
  --font-family: 'Roboto', Helvetica, Arial, sans-serif;
}

* {
  box-sizing: border-box;
}
