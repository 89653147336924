.labelCenter {
  display: flex;
  align-items: center;
  height: 100%;
}
.yAxisLabel {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  color: #636d7e;
  fill: #636d7e;
}
