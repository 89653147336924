/* Base styles for grid items */
.gridItem {
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  height: 100%;
}

.row {
  display: flex;
  gap: 2rem;
  width: 100%;
  height: calc(100% / 3 - 1.5rem);
}

.rowItem {
  position: relative;
  width: 50%;
  height: 100%;
}
.rowItem::before,
.rowItem::after {
  content: '';
  position: absolute;
  background-color: #e5ebf1;
  z-index: 1;
}

/* Horizontal line (after pseudo-element) */
.rowItem::after {
  width: calc(100% + 2rem);
  height: 1px;
  left: 0;
  top: -1rem;
}
.rowItem:nth-child(even)::after {
  width: calc(100% + 1rem);
}

/* Vertical line (before pseudo-element) */
.rowItem::before {
  width: 1px;
  height: calc(100% + 2rem);
  top: 0;
  left: -1rem;
}
.row:last-of-type .rowItem::before {
  height: calc(100% + 1rem);
}

/* Hide unnecessary lines */
.rowItem:nth-child(odd)::before,
.row:first-of-type .rowItem::after {
  display: none;
}

.infoLink {
  color: #40b975;
}
