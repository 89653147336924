.title {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
}

.labelContainer {
  display: flex;
  align-items: center;
}

.labelColor {
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 30%;
}

.valueLabel,
.valueDescription {
  font-size: 10px;
  color: #d7dbe3;
  font-weight: 400;
}

.valueDescription {
  margin-left: 16px;
}
