.label {
  margin-left: 8px;
}

.checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 0.125rem;
  background-color: transparent;
  border: 2px solid #e2e8f0;
  border-width: 2px;
  border-style: solid;
  border-color: inherit;
}

input:checked ~ .checkmark {
  background-color: #3dc679;
  border-color: #3dc679;
}

input:disabled ~ .checkmark {
  background-color: #e2e8f0;
  border: none;
}

.checkmark:after {
  content: '';
  position: absolute;
  left: 6.5px;
  top: 14.5px;
  width: 3px;
  height: 0;
  transition:
    left 0.25s,
    height 0.25s,
    top 0.25s;
  transition-timing-function: ease-out;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(38deg);
  -ms-transform: rotate(38deg);
  transform: rotate(38deg);
}

input:checked ~ .checkmark:after {
  top: 5.5px;
  height: 9px;
  left: 9.5px;
}

input:disabled ~ .checkmark:after {
  border: none;
}

.visible {
  visibility: visible;
  opacity: 1;
  transition:
    opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition:
    opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out;
}
