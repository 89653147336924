.mapboxgl-popup {
  min-width: 200px;
  max-width: 400px;
  width: 200px;
  font:
    12px/20px 'Source Sans Pro',
    'Helvetica Neue',
    Arial,
    Helvetica,
    sans-serif;
  color: white;
  pointer-events: none;
}

.mapboxgl-popup-content {
  background-color: #2d426a;
  border-radius: 6px;
  padding: 1px;
  pointer-events: none;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: #2d426a;
  margin-top: -1px;
}
.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: #243555;
  margin-bottom: -1px;
}
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #2d426a;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #2d426a;
}

.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
  border-bottom-color: #243555;
  border-width: 20px;
  margin-bottom: -10px;
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #243555;
  border-width: 20px;
  margin-bottom: -10px;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
  border-top-color: #2d426a;
  margin-top: -1px;
}

.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #2d426a;
  margin-top: -1px;
}

/* Temporarily remove logo for now */
.mapboxgl-ctrl-logo {
  display: none !important;
}
