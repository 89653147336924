.standardContent {
  color: var(--chakra-colors-text-primary);
  font-family: var(--chakra-fonts-body), 'sans-serif';
  font-weight: var(--chakra-fontWeights-normal);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--chakra-colors-text-primary);
    font-family: var(--chakra-fonts-heading), 'sans-serif';
    font-weight: var(--chakra-fontWeights-semibold);
    line-height: 1.125;

    &:first-child {
      margin-top: 0;
    }
  }

  h1 {
    font-size: var(--chakra-fontSizes-4xl);
    margin-top: var(--chakra-space-5);
    margin-bottom: var(--chakra-space-5);
  }

  h2 {
    font-size: var(--chakra-fontSizes-2xl);
    margin-top: var(--chakra-space-4);
    margin-bottom: var(--chakra-space-4);
  }

  h3 {
    font-size: var(--chakra-fontSizes-xl);
    margin-top: var(--chakra-space-2);
    margin-bottom: var(--chakra-space-2);
  }

  h4 {
    font-size: var(--chakra-fontSizes-lg);
    margin-bottom: var(--chakra-space-1);
  }

  p {
    font-size: var(--chakra-fontSizes-md);
    margin-top: var(--chakra-space-2);
    margin-bottom: var(--chakra-space-2);
  }

  ul,
  ol {
    padding-left: var(--chakra-space-6);
  }

  ul {
    li::marker {
      color: var(--chakra-colors-green-500);
    }
  }

  a {
    font-weight: var(--chakra-fontWeights-semibold);
    text-decoration: underline;
    color: var(--chakra-colors-green-500);
  }
}

.tosContent {
  color: var(--chakra-colors-text-primary);
  font-family: var(--chakra-fonts-body), 'sans-serif';
  font-weight: var(--chakra-fontWeights-normal);

  h1 {
    font-weight: 600;
    margin-bottom: 12px;
    font-size: 16px;
  }

  p {
    margin-bottom: 12px;
  }

  a {
    text-decoration: underline;
    color: var(--chakra-colors-green-500);

    &:hover {
      text-decoration: none;
    }
  }

  ul {
    list-style-position: inside;
    list-style-type: none;
    margin-top: 0;
    margin-left: 0;

    ul {
      margin-top: 12px;
      margin-left: 24px;
    }
  }

  li {
    margin-bottom: 12px;
  }
}
