.tooltipLabel {
  /* insert rules here to style tooltip label wrapper */
}

.iconContainer {
  flex: 1 1;
  max-width: var(--chakra-sizes-5);
  min-height: var(--chakra-sizes-5);
  max-height: var(--chakra-sizes-5);
  margin-inline-end: var(--chakra-space-3);
}

.icon {
  min-height: 100%;
  min-width: 100%;
}

.tooltipViewLabel {
  flex: 1 1;
  white-space: normal;
  word-wrap: break-word;
}
