.reactDataGrid
  :global(.InovuaReactDataGrid__row--even.InovuaReactDataGrid__row--selected) {
  background-color: white;
}
.reactDataGrid
  :global(.InovuaReactDataGrid__row--odd.InovuaReactDataGrid__row--selected) {
  background-color: #f1fafe;
}

.reactDataGrid
  :global(
    .InovuaReactDataGrid__row--odd:not(
        .InovuaReactDataGrid__row--scrolling
      ):not(.InovuaReactDataGrid__row--empty).InovuaReactDataGrid__row--selected
      .InovuaReactDataGrid__row-hover-target:hover
  ),
.reactDataGrid
  :global(
    .InovuaReactDataGrid__row--even:not(
        .InovuaReactDataGrid__row--scrolling
      ):not(.InovuaReactDataGrid__row--empty).InovuaReactDataGrid__row--selected
      .InovuaReactDataGrid__row-hover-target:hover
  ) {
  background: #3dc679 !important;
  color: white !important;
}

.reactDataGrid
  :global(
    .inovua-react-toolkit-checkbox.inovua-react-toolkit-checkbox--theme-green-light.inovua-react-toolkit-checkbox--indeterminate
      .inovua-react-toolkit-checkbox__icon-wrapper
      svg
  ) {
  background: #3dc679;
}

.reactDataGrid
  :global(
    .inovua-react-toolkit-checkbox.inovua-react-toolkit-checkbox--theme-green-light.inovua-react-toolkit-checkbox--unchecked
      .inovua-react-toolkit-checkbox__icon-wrapper
  ) {
  stroke: #dadcdf;
}
