.InovuaReactDataGrid--theme-green-light .InovuaReactDataGrid__row--odd {
  background: #f1fafe;
}
.InovuaReactDataGrid--theme-green-light.InovuaReactDataGrid--show-hover-rows
  .InovuaReactDataGrid__row--odd:not(.InovuaReactDataGrid__row--scrolling):not(
    .InovuaReactDataGrid__row--empty
  )
  .InovuaReactDataGrid__row-hover-target:hover {
  background: #3dc679;
  color: white;
  /* cursor: pointer; */
}
.InovuaReactDataGrid--theme-green-light.InovuaReactDataGrid--show-hover-rows
  .InovuaReactDataGrid__row--even:not(.InovuaReactDataGrid__row--scrolling):not(
    .InovuaReactDataGrid__row--empty
  )
  .InovuaReactDataGrid__row-hover-target:hover {
  background: #3dc679;
  color: white;
  /* cursor: pointer; */
}
.inovua-react-pagination-toolbar.inovua-react-pagination-toolbar--theme-green-light
  .inovua-react-pagination-toolbar__region:nth-child(3) {
  display: none;
}
.InovuaReactDataGrid--theme-green-light
  .InovuaReactDataGrid__row-active-borders-inner {
  border: none;
}
.InovuaReactDataGrid--theme-green-light
  .InovuaReactDataGrid__row--active
  .InovuaReactDataGrid__cell:not(.InovuaReactDataGrid__cell--no-background),
.InovuaReactDataGrid--theme-green-light
  .InovuaReactDataGrid__row--active.InovuaReactDataGrid__row--even:hover
  .InovuaReactDataGrid__cell:not(.InovuaReactDataGrid__cell--no-background),
.InovuaReactDataGrid--theme-green-light
  .InovuaReactDataGrid__row--active.InovuaReactDataGrid__row--odd:hover
  .InovuaReactDataGrid__cell:not(.InovuaReactDataGrid__cell--no-background) {
  color: white;
}
.InovuaReactDataGrid--theme-green-light
  .InovuaReactDataGrid__column-header--show-border-right,
.InovuaReactDataGrid--theme-green-light
  .InovuaReactDataGrid__column-header__resize-wrapper--show-border-right {
  border-right: 0px;
}
.InovuaReactDataGrid--theme-green-light.InovuaReactDataGrid--show-border-right.InovuaReactDataGrid--direction-ltr
  .InovuaReactDataGrid__cell--last:not(.InovuaReactDataGrid__cell--hidden),
.InovuaReactDataGrid--theme-green-light.InovuaReactDataGrid--show-border-right.InovuaReactDataGrid--direction-ltr
  .InovuaReactDataGrid__footer-row-cell--last:not(
    .InovuaReactDataGrid__cell--hidden
  ),
.InovuaReactDataGrid--theme-green-light.InovuaReactDataGrid--show-border-right.InovuaReactDataGrid--direction-ltr
  .InovuaReactDataGrid__locked-row-cell--last:not(
    .InovuaReactDataGrid__cell--hidden
  ),
.InovuaReactDataGrid--theme-green-light.InovuaReactDataGrid--show-border-right.InovuaReactDataGrid--direction-ltr
  .InovuaReactDataGrid__row-details.InovuaReactDataGrid__row-details--show-border-right:not(
    .InovuaReactDataGrid__row-details--min-viewport-width
  ) {
  border-right: 0px;
}
.inovua-react-toolkit-checkbox.inovua-react-toolkit-checkbox--theme-green-light.inovua-react-toolkit-checkbox--checked
  .inovua-react-toolkit-checkbox__icon-wrapper {
  fill: #3dc679;
  stroke: #ffffff;
}
.inovua-react-toolkit-menu__menu-separator:nth-last-child(2) {
  display: none;
}
.inovua-react-toolkit-menu__row:last-child {
  display: none;
}
