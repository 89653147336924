.containerContainer {
  width: 100%;
  height: 100%;
}

.meterContainer {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  aspect-ratio: 188 / 86;

  max-width: 100%;
  max-height: 100%;
  height: auto;
}

.path {
  fill: url(#gradient);
  opacity: 0.2;
  stroke-width: 1;
}

.text {
  font-family: 'Source Sans Pro';
  fill: #2d426a;
}

.mainText {
  font-size: 26px;
  font-weight: 600;
}

.unit {
  font-size: 14px;
  font-weight: 600;
}

.subText {
  font-size: 11px;
  text-anchor: middle;
  fill: #6b7a96;
}
