.tooltip-td {
  --navy: #2d426a;
  --dark-navy: #243555;
  --green: #5dda93;
  --red: #ff7e8e;
  --plot-box-bg: rgba(0, 0, 0, 0.15);
  min-width: 200px;
  max-width: 400px;
  width: 200px;
  height: 303px;
  background: var(--navy);
  position: relative;
  border-radius: 8px;
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  color: white;
  padding-bottom: 6px;
}

.header {
  padding: 8px 10px;
  background: var(--dark-navy);
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  border-radius: 8px 8px 0 0;
}

.list-info {
  padding: 8px 10px;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.info-item {
  display: flex;
  justify-content: space-between;
}

.highlight-green {
  color: var(--green);
}

.highlight-red {
  color: var(--red);
}

.divider {
  margin: 4px 10px;
  opacity: 0.15;
}

.plot-container {
  padding: 4px 10px;
}

.plot-box {
  background: var(--plot-box-bg);
  border-radius: 6px;
  padding: 6px 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.plot-box-bell {
  gap: 6px;
}

.plot-title {
  text-align: center;
  font-size: 11px;
}

.plot-item {
  width: 100%;
  position: relative;
  text-align: center;
}

/* ==============SLIDER============== */

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  margin-top: 8px;
  background: rgb(85, 163, 255);
  background: linear-gradient(
    90deg,
    rgba(85, 163, 255, 1) 0%,
    rgba(122, 236, 229, 1) 33%,
    rgba(255, 217, 118, 1) 66%,
    rgba(255, 100, 109, 1) 100%
  );
  outline: none;
  border-radius: 20px;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 3px;
  height: 20px;
  background: #ffffff;
  cursor: pointer;
  border-radius: 10px;
}

.slider::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 3px;
  height: 20px;
  background: #ffffff;
  cursor: pointer;
  border-radius: 10px;
}

.slider-popover {
  position: absolute;
  top: -10px;
  transform: translateX(-50%);
  color: white;
  font-size: 10px;
  line-height: 10px;
  white-space: nowrap;
}

/* ==============BELL CURVE============== */

.plot-axis {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
}

.plot-no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  background-color: rgba(26, 42, 72, 0.8);
  border-radius: 6px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 11px;
}
/* ==============SKELETONS============== */

.br2 {
  border-radius: 2px;
}

.brfull {
  border-radius: 20px;
}

.h10 {
  height: 10px;
  overflow: hidden;
  border-radius: 2px;
}

.h10full {
  height: 10px;
  overflow: hidden;
  border-radius: 20px;
}

.h56 {
  height: 56px;
  overflow: hidden;
  border-radius: 2px;
}
